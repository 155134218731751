import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../navigation/Navigation";
import axios from "axios";
import "./verifyPassword.css"

const VerifiyPasswordReset = () => {
  const [formData, setFormData] = useState({});
  const [responseStatus, setResponseStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { token } = useParams();

  const verifyToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/verify-reset-token/${token}`
      );
      if (response.data.status === 200) {
        setResponseStatus(true);
      } else {
        setResponseStatus(false);
      }
    } catch (error) {
      console.error("There was an error fetching the token", error);
      setResponseStatus(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevVal) => ({ ...prevVal, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
    if (!passwordRegex.test(formData.password)) {
      setErrorMessage(
        "Password must be at least 8 characters long, include one special character, and one capital letter."
      );
      return;
    }
    if (formData.password !== formData["verify-password"]) {
      setErrorMessage("Passwords do not match");
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      return;
    }
    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/reset-password/${token}`,
        {
          password: formData.password,
        }
      );
      setErrorMessage(
        "Password reset successful! You can now log in with your new password."
      );
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      setErrorMessage(
        "There was an error resetting your password. Please try again."
      );
    }
  };

  useEffect(() => {
    if (token) {
      verifyToken();
    }
  }, [token]);

  return (
    <React.Fragment>
      <Navigation />
      <div className="verify-password">
        <section className="verify-password-form">
          {responseStatus === null ? (
            <p>Loading...</p>
          ) : responseStatus ? (
            <form onSubmit={handleSubmit}>
              <h1>Enter a new password</h1>
              <div className="verfy-password-container">
                <label htmlFor="password">New password</label>
                <input
                  required
                  onChange={handleChange}
                  type="password"
                  name="password"
                  id="reset-password-email"
                  className="userInput"
                  autoComplete="current-password"
                />
              </div>
              <div className="verfy-password-container">
                <label htmlFor="verify-password">Verify new password</label>
                <input
                  required
                  onChange={handleChange}
                  type="password"
                  name="verify-password"
                  id="verify-password"
                  className="userInput"
                  autoComplete="current-password"
                />
              </div>
              <button type="submit">Submit</button>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </form>
          ) : (
            <p>
              This link has expired or is invalid. Please request a new password
              reset.
            </p>
          )}
        </section>
      </div>
    </React.Fragment>
  );
};

export default VerifiyPasswordReset;
