import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./login.css";

const Login = ({ setCurrentStep, socket, handleAlreadyPartner }) => {
  const [formData, setFormData] = useState({});
  const [verificationStatus, setVerificationStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false);
  const [renderResetPassword, setRenderResetPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setRenderResetPassword();
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/login`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      localStorage.setItem("authToken", response.data.token);
      setCurrentStep("step-two");
      setVerificationStatus(response.data.result.verificationStatus);
    } catch (error) {
      setErrorMessage(error.response.data.message || "An error occurred");
      setLoading(false);
    }
  };

  const handleResetPassword = () => {
    setRenderResetPassword(true);
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(
      "If there is an email that matches this on our database we will send it a secure reset password link."
    );
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/reset-password`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
    }
  };

  const handleBackToLogin = () => {
    setRenderResetPassword(false);
  };

  useEffect(() => {
    if (verificationStatus) {
      switch (verificationStatus) {
        case "pending":
          navigate("/register/awaiting-review");
          break;
        case "verified":
          navigate("/manager/orders");
          break;
        default:
          console.log("Unexpected status:", verificationStatus);
      }
    }
  }, [verificationStatus, navigate]);

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      navigate("/manager/orders");
    }
    if (socket) {
      socket.disconnect();
    }
  }, []);

  return (
    <React.Fragment>
      {!renderResetPassword ? (
        <form onSubmit={handleSubmit}>
          <h1>
            <span className="name">B-Street</span> Partners
          </h1>
          <div className="login-credentials">
            <label htmlFor="businessEmail">Business Email</label>
            <input
              onChange={handleChange}
              required
              autoComplete="username"
              type="email"
              name="businessEmail"
              id="businessEmail"
            />
          </div>
          <div className="login-credentials">
            <label htmlFor="password">Password</label>
            <input
              onChange={handleChange}
              required
              autoComplete="current-password"
              type="password"
              name="password"
              id="password"
            />
          </div>
          {errorMessage && (
            <div className="error-message">
              <p>{errorMessage}</p>
            </div>
          )}
          <p className="already-a-partner" onClick={handleResetPassword}>
            Reset Password?
          </p>
          <p className="already-a-partner" onClick={handleAlreadyPartner}>
            Become a B-Street Partner?
          </p>
          {loading ? (
            <button>Loading...</button>
          ) : (
            <button type="submit">Login</button>
          )}
        </form>
      ) : (
        <form onSubmit={handleResetPasswordSubmit}>
          <h1>
            <span className="name">Reset Password</span>
          </h1>
          <div className="login-credentials">
            <label htmlFor="businessEmail">Business Email</label>
            <input
              onChange={handleChange}
              required
              type="email"
              name="businessEmail"
              id="businessEmail"
            />
          </div>
          {errorMessage && (
            <div className="error-message">
              <p>{errorMessage}</p>
            </div>
          )}
          {loading ? (
            <button>Loading...</button>
          ) : (
            <button type="submit">Send Reset Email</button>
          )}
          <p className="already-a-partner" onClick={handleBackToLogin}>
            Back to Login
          </p>
        </form>
      )}
    </React.Fragment>
  );
};

export default Login;
