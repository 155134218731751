import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const ProgressStepTwo = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        businessAddress: {
            streetAddress: '',
            aptSuite: '',
            city: '',
            state: '',
            zipCode: '',
        },
        businessType: 'Clothing and Apparel', 
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('businessAddress')) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                businessAddress: {
                    ...prevFormData.businessAddress,
                    [name.split('.')[1]]: value,
                },
            }));
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        };
    };

    const fetchAddress = async() => {
        const token = localStorage.getItem('authToken');
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/profile`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if(response.data.user.verificationStatus === 'verified'){
                navigate('/manager/orders')
            }else if (response.data.user.businessAddress) {
                navigate('/register/account-setup');
            };
        } catch (error) {
            console.error("There was an error with fetching the profile")
        };
    };

    useEffect(()=>{
        fetchAddress();
    },[]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('authToken');
        try {
            await axios.post(
                `${process.env.REACT_APP_SERVER_ENDPOINT}/profile/add/address`, formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            navigate('/register/account-setup');
        } catch (error) {
            console.error(
                'There was an error adding your profile picture',
                error.response?.data || error.message
            );
        };
    };
  return (
    <>
        <form onSubmit={handleSubmit}>
            <div className='setting-input-container'>
                <label htmlFor='businessStreetAddress'>Business Address (The pickup address)</label>
                <input 
                    type='text' 
                    name='businessAddress.streetAddress' 
                    id='businessStreetAddress' 
                    value={formData.businessAddress.streetAddress}
                    onChange={handleChange}
                    placeholder='123-11 B-Street Blvd'
                />
            </div>
            <div className='setting-input-container'>
                <label htmlFor='businessAptSuite'>Business Suite or Apt</label>
                <input 
                    type='text' 
                    name='businessAddress.aptSuite' 
                    id='businessAptSuite' 
                    value={formData.businessAddress.aptSuite}
                    onChange={handleChange}
                    placeholder='3D'
                />
            </div>
            <div className='setting-input-container'>
                <label htmlFor='businessCity'>Business City</label>
                <input 
                    type='text' 
                    name='businessAddress.city' 
                    id='businessCity' 
                    value={formData.businessAddress.city}
                    onChange={handleChange}
                    placeholder='New York City'
                />
            </div>
            <div className='setting-input-container'>
                <label htmlFor='businessState'>Business State</label>
                <input 
                    type='text' 
                    name='businessAddress.state' 
                    id='businessState' 
                    value={formData.businessAddress.state}
                    onChange={handleChange}
                    placeholder='New York'
                />
            </div>
            <div className='setting-input-container'>
                <label htmlFor='businessZipCode'>Business Zip Code</label>
                <input 
                    type='text' 
                    name='businessAddress.zipCode' 
                    id='businessZipCode' 
                    value={formData.businessAddress.zipCode}
                    onChange={handleChange}
                    placeholder='11212'
                />
            </div>
            <div className='setting-input-container'>
                <label htmlFor='businessType'>Business Type</label>
                <select name="businessType" defaultValue={formData.businessType} onChange={handleChange}>
                    <option value="Clothing and Accessories">Clothing and Accessories</option>
                    <option value="Beauty and Skincare">Beauty and Skincare</option>
                    <option value="Handmade Goods">Handmade Goods</option>
                    <option value="Health and Wellness">Health and Wellness</option>
                </select>
            </div>
            <button type='submit'>Submit</button>
        </form>
    </>
  )
}

export default ProgressStepTwo